import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout location={props.location} nodeLocale='tr-TR'>
    <SEO title="404: Not found" location={props.location}/>
    <h1>404: Bu sayfayı bulamadık...</h1>
    <p>Olmayan bir sayfayı çağırdın. </p>
  </Layout>
)

export default NotFoundPage
